import { FC, memo } from 'react';
import Link from 'next/link';
import { format, isValid } from 'date-fns';
import { Icons } from '@studenten/ui-components';

import { TextLink } from 'src/common/TextLink';
import { ImageWithFallback } from 'src/common/ImageWithFallback';
import { APP_ROUTES } from 'constants/routes';
import { wrapImageUrl } from 'api_entities/strapi/utils';
import * as types from 'api_entities/strapi/types';

import placeholder from 'public/assets/placeholders/placeholder-1.svg';

import {
  ImageWrapper,
  CardContainer,
  CardInner,
  TagsRow,
  ArticleLinkWrapper,
  Title,
  ArticleInfo,
  ArticleWrapper,
  Tag,
} from './BlogPost.styles';

export type BlogPostProps = {
  title: string;
  publicationDate: string;
  slug: string;
  category?: types.ArticleCategory;
  imageSrc?: string;
  tags?: types.ArticleTag[];
  timeToRead?: number;
};

const BlogPost: FC<BlogPostProps> = ({
  slug,
  title,
  tags,
  timeToRead,
  imageSrc,
  category,
  publicationDate = '',
}) => {
  const pubDate = new Date(publicationDate);
  const published = isValid(pubDate) ? format(pubDate, 'MMM d, yyyy') : '';
  const href = category
    ? `${APP_ROUTES.BLOG}/${category?.attributes?.slug}/${slug}`
    : `${APP_ROUTES.BLOG}/${slug}`;  // TODO: this will lead nowhere
  const imgSrc = imageSrc ? wrapImageUrl(imageSrc) : placeholder;

  return (
    <CardContainer>
      <CardInner>
        <ImageWrapper>
          <ImageWithFallback
            priority
            layout="fill"
            objectFit="cover"
            src={imgSrc}
            fallbackSrc={placeholder}
          />
        </ImageWrapper>
        <TagsRow>
          {tags?.map(({ title, id }) => (
            <Tag key={id}>{title}</Tag>
          ))}
        </TagsRow>
        <ArticleInfo>
          {published} • {timeToRead} leestijd
        </ArticleInfo>
        <ArticleWrapper>
          <Link passHref href={href}>
            <a>
              <Title>{title}</Title>
            </a>
          </Link>
          <ArticleLinkWrapper>
            <TextLink href={href} size="m">
              Lees meer
              <Icons.Arrow />
            </TextLink>
          </ArticleLinkWrapper>
        </ArticleWrapper>
      </CardInner>
    </CardContainer>
  );
};

export default memo(BlogPost);
