import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const CardContainer = styled.article`
  height: 396px;
  width: 100%;
  max-width: 100%;
  border-radius: 16px;
  overflow: hidden;

  background-color: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 16px;

  ${up('md')} {
    padding: 24px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 20px;
`;

export const TagsRow = styled.div`
  margin-bottom: 20px;
`;

export const Tag = styled.span`
  display: inline-block;
  margin-left: 8px;
  background: #fdf2e5;
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 8px 12px;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const ArticleInfo = styled.p`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.25;
  text-transform: uppercase;

  color: ${({ theme }) => theme.system.black};
`;

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.h3`
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.25;
  text-overflow: ellipsis;
  overflow: hidden;

  color: ${({ theme }) => theme.system.black};
`;

export const ArticleLinkWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: auto;
  a {
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 8px;
    font-size: 20px;
  }
`;
