import { FC, ReactNode, memo } from 'react';
import Link from 'next/link';
import { urlFormatter } from '../../../utils/helpers';

import { StyledLink } from './TextLink.styles';

type TextLinkProps = {
  href: string;
  children: ReactNode;
  color?: string;
  underline?: boolean;
  size?: 's' | 'm' | 'l';
  weight?: number;
  isExternal?: boolean;
  rel?: string;
  target?: string;
};

const TextLink: FC<TextLinkProps> = ({
  isExternal,
  href,
  children,
  color,
  underline,
  size,
  weight,
  target,
}) => {
  if (isExternal) {
    return (
      <StyledLink
        rel={isExternal && 'noopener nofollower'}
        href={urlFormatter(href)}
        color={color}
        underline={underline}
        size={size}
        weight={weight}
        {...(target && { target })}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <Link passHref href={href}>
      <StyledLink
        color={color}
        underline={underline}
        size={size}
        weight={weight}
        {...(target && { target })}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default memo(TextLink);
