import { FC, memo } from 'react';
import Link from 'next/link';
import { APP_ROUTES } from 'constants/routes';

// Components
import { Icons, MobileAccordion } from '@studenten/ui-components';
import { useFavorite } from 'hooks/useFavorite';
import { ImageWithFallback } from 'src/common';

import placeholder from 'public/assets/placeholders/placeholder-19.svg';

// Styles
import {
  ListItem,
  TopBlock,
  BottomBlock,
  MainContent,
  Favorite,
  CardText,
  Title,
  ImageWrapper,
  DescriptionText,
} from './GenericFavouriteCard.styles';

// Types
import { GenericFavouriteCardProps } from './types';

import { checkImgUrl } from '../utils';

const getCategoryUrl = (category: GenericFavouriteCardProps['category']): string | null => {
  switch (category) {
    case 'local_business':
    case 'company': {
      return APP_ROUTES.COMPANIES;
    }

    case 'student_housing': {
      return APP_ROUTES.STUDENT_HOUSING;
    }

    case 'student_society': {
      return APP_ROUTES.STUDENT_SOCIETIES;
    }

    case 'job': {
      return APP_ROUTES.ALL_VACANCIES;
    }

    default: {
      return null;
    }
  }
};

const GenericFavouriteCard: FC<GenericFavouriteCardProps> = (props) => {
  const { objectID, title, shortDescription, photoURL, isMobile, slug, category } = props;

  const categoryUrl = getCategoryUrl(category);

  const [isFavorite, onFavoriteClick] = useFavorite(category, objectID);
  const fromContentful = checkImgUrl(photoURL);

  const imgSrc = fromContentful ? `https:${photoURL}` : photoURL ? photoURL : placeholder;

  if (isMobile) {
    return (
      <ListItem>
        <TopBlock>
          <ImageWrapper>
            <ImageWithFallback
              priority
              unoptimized={!fromContentful && imgSrc !== placeholder}
              fallbackSrc={placeholder}
              src={imgSrc}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </ImageWrapper>
          <MainContent>
            <Title>
              {categoryUrl ? (
                <Link passHref href={`${categoryUrl}/${slug}`}>
                  <a>{title}</a>
                </Link>
              ) : (
                <span>{title}</span>
              )}
            </Title>
            <Favorite aria-label="add to favorites" onClick={onFavoriteClick}>
              {isFavorite ? <Icons.Heart /> : <Icons.HeartOutline />}
            </Favorite>
          </MainContent>
        </TopBlock>
        <BottomBlock>
          {shortDescription && (
            <MobileAccordion>
              <DescriptionText
                className="short-description"
                dangerouslySetInnerHTML={{ __html: shortDescription }}
              />
            </MobileAccordion>
          )}
        </BottomBlock>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <TopBlock>
        <ImageWrapper>
          <ImageWithFallback
            priority
            unoptimized={!fromContentful && imgSrc !== placeholder}
            fallbackSrc={placeholder}
            src={imgSrc}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </ImageWrapper>
        <MainContent>
          <Title>
            {categoryUrl ? (
              <Link passHref href={`${categoryUrl}/${slug}`}>
                <a dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
            ) : (
              <span>{title}</span>
            )}
          </Title>
          <Favorite aria-label="add to favorites" onClick={onFavoriteClick}>
            {isFavorite ? <Icons.Heart /> : <Icons.HeartOutline />}
          </Favorite>
          {shortDescription && (
            <CardText
              className="short-description"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          )}
        </MainContent>
      </TopBlock>
    </ListItem>
  );
};

export default memo(GenericFavouriteCard);
