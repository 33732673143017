import { FC, memo, MouseEventHandler } from 'react';
import Link from 'next/link';
import { APP_ROUTES } from 'constants/routes';

// Components
import { Icons, MobileAccordion } from '@studenten/ui-components';
import { useFavorite } from 'hooks/useFavorite';
import { ImageWithFallback } from 'src/common';

import placeholder from 'public/assets/placeholders/placeholder-19.svg';
// Styles
import {
  ListItem,
  TopBlock,
  BottomBlock,
  MainContent,
  LeftBlock,
  RightBlock,
  Favorite,
  SubscribeButton,
  CardText,
  Title,
  ImageWrapper,
  Tags,
  TagType,
  TagSalary,
  TagCity,
  DayTimePeriod,
  ButtonMobileWrapper,
  DescriptionText,
} from './SearchVacancyCard.styles';

// Types
import { VacancyCardProps } from './types';

import { checkImgUrl, normalizeSalary } from '../utils';

const SearchVacancyCard: FC<VacancyCardProps> = (props) => {
  const {
    objectID,
    title,
    shortDescription,
    type,
    city,
    photoURL,
    isMobile,
    slug,
    salaryCurrency,
    salaryUnit,
    salaryMin,
    salaryMax,
    employmentType,
    onApplyClick,
  } = props;
  const [isFavorite, onFavoriteClick] = useFavorite('job', objectID);
  const fromContentful = checkImgUrl(photoURL);

  const imgSrc = fromContentful ? `https:${photoURL}` : photoURL ? photoURL : placeholder;

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    onApplyClick(slug);
  };

  const _employmentType = (employmentType || []).join(', ');

  const salary = normalizeSalary({ salaryMax, salaryMin, salaryUnit, salaryCurrency });

  if (isMobile) {
    return (
      <ListItem>
        <TopBlock>
          <ImageWrapper>
            <ImageWithFallback
              priority
              unoptimized={!fromContentful && imgSrc !== placeholder}
              fallbackSrc={placeholder}
              src={imgSrc}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </ImageWrapper>
          <MainContent>
            <Title>
              <Link passHref href={`${APP_ROUTES.JOBS}/${slug}`}>
                <a>{title}</a>
              </Link>
            </Title>
            <Favorite aria-label="add to favorites" onClick={onFavoriteClick}>
              {isFavorite ? <Icons.Heart /> : <Icons.HeartOutline />}
            </Favorite>
          </MainContent>
        </TopBlock>
        <BottomBlock>
          <Tags>
            {type && <TagType>{type}</TagType>}
            {salary && <TagSalary>{salary}</TagSalary>}
            {city && <TagCity>{city.join(', ')}</TagCity>}
          </Tags>
          <DayTimePeriod>{_employmentType}</DayTimePeriod>
          {shortDescription && (
            <MobileAccordion>
              <DescriptionText
                className="short-description"
                dangerouslySetInnerHTML={{ __html: shortDescription }}
              />
            </MobileAccordion>
          )}
          <ButtonMobileWrapper>
            <SubscribeButton variant="base" onClick={onButtonClick}>
              Solliciteer Direct
            </SubscribeButton>
          </ButtonMobileWrapper>
        </BottomBlock>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <TopBlock>
        <ImageWrapper>
          <ImageWithFallback
            priority
            unoptimized={!fromContentful && imgSrc !== placeholder}
            fallbackSrc={placeholder}
            src={imgSrc}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        </ImageWrapper>
        <MainContent>
          <Title>
            <Link passHref href={`${APP_ROUTES.ALL_VACANCIES}/${slug}`}>
              <a dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </Title>
          <Favorite aria-label="add to favorites" onClick={onFavoriteClick}>
            {isFavorite ? <Icons.Heart /> : <Icons.HeartOutline />}
          </Favorite>
          {shortDescription && (
            <CardText
              className="short-description"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          )}
        </MainContent>
      </TopBlock>
      <BottomBlock>
        <LeftBlock>
          <Tags>
            {type && <TagType>{type}</TagType>}
            {salary && <TagSalary>{salary}</TagSalary>}
            {city && <TagCity>{city.join(', ')}</TagCity>}
          </Tags>
          <DayTimePeriod>{_employmentType}</DayTimePeriod>
        </LeftBlock>
        <RightBlock>
          <SubscribeButton variant="base" onClick={onButtonClick}>
            Solliciteer Direct
          </SubscribeButton>
        </RightBlock>
      </BottomBlock>
    </ListItem>
  );
};

export default memo(SearchVacancyCard);
