//Styles
import styled from 'styled-components';

// Constants
import { device } from 'constants/device';

export const Title = styled.span`
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0;
  color: ${({ theme }) => theme.system.black};

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    -webkit-line-clamp: 2;
  }
`;

export const DescriptionText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 400;

  &, & * {
    color: ${({ theme }) => theme.system.darkGreyActive} !important;
  }
`;

export const CardText = styled(DescriptionText)`
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
`;

export const ListItem = styled.div`
  background-color: ${({ theme }) => theme.system.white};
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
`;

export const TopBlock = styled.div`
  display: flex;
  min-width: 0;
`;

export const BottomBlock = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Title} {
    padding-right: 28px;
  }
`;

export const Favorite = styled.button`
  border: none;
  background: none;
  padding: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${({ theme }) => theme.brand.blue};
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 8px;

  @media ${device.tablet} {
    width: 48px;
    height: 48px;
  }
`;

export const BottomTextWrapper = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
`;

export const Tag = styled(BottomTextWrapper)`
  position: relative;
  display: inline-block;
  padding: 4px 4px 4px 24px;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }

  &:before {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 6px;
    left: 4px;
  }
`;
