import styled from 'styled-components';

type StyledLinkProps = {
  color?: string;
  underline?: boolean;
  size?: 's' | 'm' | 'l';
  weight?: number;
};

export const StyledLink = styled.a<StyledLinkProps>`
  font-style: normal;
  display: initial;
  cursor: pointer;
  font-weight: ${(props) => (props.weight ? props.weight : 600)};
  font-size: ${(props) => {
    switch (props.size) {
      case 's': {
        return '14px';
      }
      case 'm': {
        return '16px';
      }
      case 'l': {
        return '18px';
      }
      default: {
        return '16px';
      }
    }
  }};
  line-height: 22px;
  text-decoration-line: ${(props) => (props.underline ? 'underline' : 'none')};
  color: ${(props) => (props.color ? props.color : props.theme.brand.orange)};
  position: relative;
`;
